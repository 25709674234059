.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.loadicon {
  width: 80px;
  height: 80px;
  margin: 32px;

  & div {
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 3px solid var(--color-eerie-black);
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-name: ripple;

    &:nth-child(2) {
      animation-delay: 0.5s;
    }
  }
}

@keyframes ripple {
  from {
    transform: scale(0);
    opacity: 1;
  }

  to {
    transform: scale(1);
    opacity: 0;
  }
}
