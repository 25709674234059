html {
  word-break: initial;
}

html,
body {
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  font-size: var(--fs-main);
  line-height: var(--lh-main);
  color: var(--color-duse);
}

body,
button,
input {
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2,
h3,
h4,
h5,
h6 {
  margin: 0;  
}

h1,
h2 {
  font-family: var(--font-family-header);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button,
input,
select,
textarea {
  background: transparent;
  color: inherit;
}

::-webkit-input-placeholder {
  color: initial;
  opacity: initial;
}
