:root {
  /* Font sizes and line heights */

  /* LARGE */
  --fs-large: 30px;
  --lh-large: 31px;

  /* MAIN */
  --fs-main: 18px;
  --lh-main: 28px;

  /* HERO TITLE */
  --fs-herotitle: 50px;
  --lh-herotitle: 50px;

  /* HERO TEXT */
  --fs-herotext: 130px;
  --lh-herotext: 163px;

  /* HEADLINE */
  --fs-headline: 70px;
  --lh-headline: 80px;

  /* SMALL */
  --fs-small: 14px;
  --lh-small: 20px;
  --fs-small-italic: var(--fs-small);
  --lh-small-italic: var(--lh-small);

  /* Font as variable */
  --font-family: 'Josefin Slab', sans-serif;
  --font-family-header: 'Great Vibes', sans-serif;

  /* Font-weight as variable */
  --fw-light: 300;
  --fw-normal: 400;
  --fw-bold: 700;
}

@media (--belowScreenExtraLarge) {
  :root {

    /* LARGE 18px -> 24px */
    --fs-large: calc(1.5625rem + ((1vw - 7.68px) * 0.434));
    --lh-large: calc(1.5625rem + ((1vw - 7.68px) * 0.434));

    /* MAIN 14px -> 18px */
    --fs-main: calc(0.0045 * 100vw + 13px);
    --lh-main: calc(0.0091 * 100vw + 17px);

    /* HEROTITLE 27x -> 60px */
    --fs-herotitle: calc(1.5625rem + ((1vw - 7.68px) * 2.1701));
    --lh-herotitle: calc(1.5625rem + ((1vw - 7.68px) * 2.1701));

    --fs-herotext: calc(6.25rem + ((1vw - 7.68px) * 2.6042));
    --lh-herotext: calc(7.5rem + ((1vw - 7.68px) * 3.7326));

    /* HEADLINE 40x -> 60px */
    --fs-headline: calc(3.125rem + ((1vw - 7.68px) * 1.7361));
    --lh-headline: calc(1.5625rem + ((1vw - 7.68px) * 2.1701));

    /* SMALL 12px -> 14px */
    --fs-small: calc(0.0023 * 100vw + 11px);
    --lh-small: calc(0.0023 * 100vw + 17px);
    --fs-small-italic: calc(0.0045 * 100vw + 9px);
    --lh-small-italic: calc(0.0068 * 100vw + 12px);
  }
}
